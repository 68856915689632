import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import InputDateRange from '@atom/components/common/inputDateRange/InputDateRange';
import UsersFilter from '@atom/components/common/usersFilter/UsersFilter';
import { Select, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetRequestsFilters } from '@atom/types/assetRequest';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  DateRange,
  DateRangeTerminator,
} from '@atom/utilities/workOrdersDateFilterUtilities';

import { STATUS_LABELS } from '../assetRequestConstants';
import { getSDDOTStatusOptions } from '../customTenantUtilities';

import './sddotFilters.css';

const { MenuItem } = Select;

const styles = {
  input: {
    marginTop: '1rem',
  },
  label: {
    color: colors.neutral.dim,
  },
};

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
});

interface Props {
  filtersCart: AssetRequestsFilters;
  setFiltersCart: Dispatch<SetStateAction<AssetRequestsFilters>>;
  createdPresetSelected?: DateRange;
  setCreatedPresetSelect?: Dispatch<SetStateAction<DateRange>>;
}

const SDDOTReplenishmentsFilters = ({
  filtersCart,
  setFiltersCart,
  createdPresetSelected,
  setCreatedPresetSelect,
}: Props) => {
  const classes = useClasses();

  const updateFilter = (value: any, property: keyof AssetRequestsFilters) => {
    setFiltersCart(prev => ({ ...prev, [property]: value }));
  };

  const getDateValue = (property: keyof AssetRequestsFilters) => {
    if (!isNilOrEmpty(filtersCart[property])) {
      return new Date(Number(filtersCart[property]));
    }

    return null;
  };

  const handleDateChange = (
    property: keyof AssetRequestsFilters,
    terminator: DateRangeTerminator,
  ) => (val: Date) => {
    const dateMillis = val
      ? terminator === DateRangeTerminator.START
        ? convertDateToLocalDayStart(val)
        : convertDateToLocalDayEnd(val)
      : null;
    updateFilter(dateMillis, property);
  };

  const statusOptions = getSDDOTStatusOptions(filtersCart.type);

  return (
    <>
      <div styleName="input-container">
        <TextField
          style={styles.input}
          InputLabelProps={{ style: styles.label }}
          type="number"
          value={Number(filtersCart.name)}
          placeholder="Enter number only"
          label="Request ID"
          onChange={event => updateFilter(String(event.target.value), 'name')}
        />
      </div>
      <div styleName="input-container">
        <Select
          key="status"
          multiple
          displayEmpty
          label="Status"
          value={filtersCart?.statuses || []}
          onChange={event => updateFilter(event.target.value, 'statuses')}
          renderValue={R.length(filtersCart?.statuses) > 0 ? null : () => 'All'}
          InputLabelProps={{ classes: { root: classes.label } }}
        >
          {statusOptions.map(status => (
            <MenuItem key={status} value={status}>
              {STATUS_LABELS[status]}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div styleName="input-container">
        <UsersFilter
          value={filtersCart?.assignedTo}
          updateValue={newValue => updateFilter(newValue, 'createdBy')}
          placeholder="Search users"
          label="Created By"
          showTitle
        />
      </div>
      <div styleName="input-container">
        <InputDateRange
          label="Created Date"
          presetSelected={createdPresetSelected}
          startDateValue={getDateValue('createdDateStart')}
          handleStartChange={handleDateChange(
            'createdDateStart',
            DateRangeTerminator.START,
          )}
          endDateValue={getDateValue('createdDateEnd')}
          handleEndChange={handleDateChange(
            'createdDateEnd',
            DateRangeTerminator.END,
          )}
          handleSelectDatePreset={selected =>
            setCreatedPresetSelect(selected?.value)
          }
        />
      </div>
    </>
  );
};

export default SDDOTReplenishmentsFilters;
