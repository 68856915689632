import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { StringParam, useQueryParams } from 'use-query-params';

import TeamContext, {
  baseUserGroup,
  Section,
  View,
} from '@atom/components/teamPortal/TeamContext';
import { GET_PAY_PERIODS } from '@atom/graph/payPeriod';
import { GET_USER } from '@atom/graph/user';
import {
  GET_USER_GROUP_TREE,
  GET_USERS_AND_USER_GROUPS,
} from '@atom/graph/userGroup';
import { usePreferences } from '@atom/hooks/usePreferences';
import {
  INIT_PAGINATION,
  TeamNavigation,
  useTeamNavigation,
} from '@atom/hooks/useTeamNavigation';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  getUserGroupTableData,
  getUserGroupTableTotalCount,
} from '@atom/selectors/userGroupSelectors';
import { PayPeriod, PayPeriods, PayPeriodsInput } from '@atom/types/payPeriod';
import { UserDetail } from '@atom/types/user';
import {
  UserFilters,
  UserGroupChildrenConnectionInput,
  UserGroupTreeType,
} from '@atom/types/userGroups';
import { FILES_USERS_CSV_DOWNLOAD_ENDPOINT } from '@atom/utilities/endpoints';
import { buildEndpointWithParamsFromData } from '@atom/utilities/requestUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import {
  convertDateToMillisGMTMidday,
  getPayPeriodWeeks,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RolesTable from './rolesTable/RolesTable';
import TeamMapView from './teamMapView/TeamMapView';
import TeamSidebar from './teamSidebar/TeamSidebar';
import TimeSheet from './timeSheet/TimeSheet';
import TimeSheetList from './timeSheetList/TimeSheetList';
import UserGroupsTable from './userGroupsTable/UserGroupsTable';
import UsersTable from './usersTable/UsersTable';
import TeamHeader from './TeamHeader';

import './teamPortal.css';

const TeamPortal = () => {
  const userProfile = useUserProfile();
  const preferences = usePreferences();
  const [queryParams, setQueryParams] = useQueryParams({
    view: StringParam,
  });

  const { teamNavigation, setTeamNavigation } = useTeamNavigation();
  const {
    expanded,
    activeView,
    activeGroup,
    activePath,
    activeTimeSheetGroup,
    selectedPayPeriod,
    payPeriodYear,
    payPeriodWeeks,
    selectedTimeSheetUser,
    page,
    limit,
  } = teamNavigation;

  const setTeamNavigationValues = (updated: Partial<TeamNavigation>) => {
    setTeamNavigation(updated);
  };

  const setActiveGroup = (group: UserGroupTreeType) =>
    setTeamNavigationValues({ activeGroup: group });

  const setActivePath = (groups: UserGroupTreeType[]) =>
    setTeamNavigationValues({ activePath: groups });

  const setActiveTimeSheetGroup = (group: any) =>
    setTeamNavigationValues({ activeTimeSheetGroup: group });

  const setPayPeriodYear = (year: number) =>
    setTeamNavigationValues({ payPeriodYear: year });

  const setSelectedPayPeriod = (payPeriod: PayPeriod) =>
    setTeamNavigationValues({ selectedPayPeriod: payPeriod });

  const setSelectedTimeSheetUser = (user: any) =>
    setTeamNavigationValues({ selectedTimeSheetUser: user });

  const setPage = (newPage: number) =>
    setTeamNavigationValues({ page: newPage });

  const setLimit = (newLimit: number) =>
    setTeamNavigationValues({ limit: newLimit });

  const [isMapView, setIsMapView] = useState<boolean>(false);
  const [csvDownloadEndpoint, setCsvDownloadEndpoint] = useState<string>('');
  const [filters, setFilters] = useState<UserFilters>({});
  const [open, setOpen] = useState<Set<string>>(new Set(['']));

  useEffect(() => {
    const newActiveView = !queryParams?.view
      ? View.DIRECTORY
      : (queryParams.view as View);

    // Keep existing pagination on return to active view
    const updatedValues =
      newActiveView === activeView
        ? { activeView: newActiveView }
        : { activeView: newActiveView, ...INIT_PAGINATION };
    setTeamNavigationValues(updatedValues);
  }, [queryParams, payPeriodWeeks]);

  useEffect(() => {
    const updateParams = () => {
      setQueryParams({
        view: queryParams?.view || View.DIRECTORY,
      });
    };
    updateParams();
  }, []);

  const navigateToView = (view: View) => setQueryParams({ view });

  const onPayPeriodsCompleted = data => {
    const currentPeriod = data?.payPeriods?.currentPeriod || 1;

    const currentPayPeriod = R.pathOr([], ['payPeriods', 'periods'], data).find(
      item => item.period === currentPeriod,
    );

    setSelectedPayPeriod(currentPayPeriod);
  };

  const [
    getPayPeriods,
    { loading: payPeriodsLoading, data: payPeriodsData },
  ] = useLazyQuery<{ payPeriods: PayPeriods }, { input: PayPeriodsInput }>(
    GET_PAY_PERIODS,
    {
      onCompleted: onPayPeriodsCompleted,
    },
  );

  const { loading: userLoading, data: userData } = useQuery<
    { user: UserDetail },
    { id: string }
  >(GET_USER, {
    variables: {
      id: userProfile.userId,
    },
    // using cache-first to ensure this is not re-queried
    //   via GET_USER call in ManageUserGroupModal
    fetchPolicy: 'cache-first',
  });

  const {
    loading: treeLoading,
    data: userGroupTreeData,
    refetch: refetchTree,
  } = useQuery(GET_USER_GROUP_TREE, {
    fetchPolicy: 'network-only',
  });

  const {
    loading: tableLoading,
    data: tableData,
    refetch: refetchTable,
  } = useQuery<
    { userGroup: UserGroupTreeType },
    { id: string; input: UserGroupChildrenConnectionInput }
  >(GET_USERS_AND_USER_GROUPS, {
    variables: {
      id: activeGroup.id,
      input: {
        page,
        limit,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (preferences?.timeTracking?.timesheet) {
      getPayPeriods({
        variables: {
          input: {
            year: payPeriodYear,
            currentDate: convertDateToMillisGMTMidday(new Date()),
          },
        },
      });
    }
  }, [payPeriodYear]);

  useEffect(() => {
    if (preferences?.timeTracking) {
      const newPayPeriodWeeks = getPayPeriodWeeks(selectedPayPeriod);
      setTeamNavigationValues({
        payPeriodWeeks: newPayPeriodWeeks,
      });
    }
  }, [selectedPayPeriod]);

  useEffect(() => {
    const endpoint = buildEndpointWithParamsFromData(
      FILES_USERS_CSV_DOWNLOAD_ENDPOINT,
      filters,
    );

    setCsvDownloadEndpoint(endpoint);
  }, [activeView, filters]);

  useEffect(() => {
    setOpen(new Set([...open, ...activePath.map(path => path.id)]));
  }, [activePath.length]);

  const toggleOpen = (id: string) => {
    setOpen(toggleFromSet(open, id));
  };

  const toggleExpanded = (section: Section) => {
    setTeamNavigationValues({ expanded: toggleFromSet(expanded, section) });
  };

  const handleGroup = (
    userGroup: UserGroupTreeType,
    path: UserGroupTreeType[],
  ) => {
    setTeamNavigationValues({
      ...INIT_PAGINATION,
      activeGroup: userGroup,
      activePath: path,
    });
  };

  const timeEntryApproverUserGroups = useMemo(
    () => R.pathOr([], ['timeEntryApproverUserGroups'], userData?.user),
    [userData],
  );

  // Auto-select first timeSheet group if present
  useEffect(() => {
    if (
      preferences?.timeTracking &&
      activeView === View.TIME_SHEET_LIST &&
      isNilOrEmpty(activeTimeSheetGroup) &&
      !isNilOrEmpty(timeEntryApproverUserGroups)
    ) {
      setActiveTimeSheetGroup(timeEntryApproverUserGroups[0]);
    }
  }, [timeEntryApproverUserGroups.length]);

  const getContent = () => {
    const payPeriodReady = !isNilOrEmpty(selectedPayPeriod);
    const hasSelectedGroup = !isNilOrEmpty(activeTimeSheetGroup);
    const views = {
      [View.DIRECTORY]: <UserGroupsTable />,
      [View.ACTIVE]: <UsersTable />,
      [View.PENDING]: <UsersTable />,
      [View.DEACTIVATED]: <UsersTable />,
      [View.TIME_SHEET]: payPeriodReady ? <TimeSheet /> : <UserGroupsTable />,
      [View.TIME_SHEET_LIST]:
        payPeriodReady && hasSelectedGroup ? (
          <TimeSheetList />
        ) : (
          <UserGroupsTable />
        ),
      [View.ROLES]: <RolesTable />,
    };

    return views[activeView];
  };

  const contextValue = {
    treeLoading,
    userGroupTree: R.pathOr(
      baseUserGroup,
      ['userGroupsTree'],
      userGroupTreeData,
    ),
    refetchTree,
    setFilters,
    setActiveGroup,
    activeView,
    isMapView,
    setIsMapView,
    activeGroup,
    handleGroup,
    activePath,
    setActivePath,
    csvDownloadEndpoint,
    open,
    toggleOpen,
    page,
    setPage,
    limit,
    setLimit,
    totalCount: getUserGroupTableTotalCount(tableData),
    table: getUserGroupTableData(tableData),
    tableLoading,
    refetchTable,
    payPeriodYear,
    setPayPeriodYear,
    selectedPayPeriod,
    setSelectedPayPeriod,
    payPeriodsLoading,
    payPeriods: payPeriodsData?.payPeriods || {
      periods: [],
      currentPeriod: 0,
      availableYears: [],
    },
    activeTimeSheetGroup,
    setActiveTimeSheetGroup,
    payPeriodWeeks,
    selectedTimeSheetUser,
    setSelectedTimeSheetUser,
    userLoading,
    timeEntryApproverUserGroups,
    currentUserHasUserGroups:
      R.pathOr([], ['userGroups'], userData?.user).length > 0,
    expanded,
    toggleExpanded,
    navigateToView,
    setTeamNavigationValues,
  };

  return (
    <TeamContext.Provider value={contextValue}>
      <div styleName="portal-container">
        <TeamHeader />
        {isMapView ? (
          <div styleName="map-container">
            <TeamMapView />
          </div>
        ) : (
          <div styleName="content-container">
            <TeamSidebar />
            <div styleName="right-pane">{getContent()}</div>
          </div>
        )}
      </div>
    </TeamContext.Provider>
  );
};

export default TeamPortal;
