import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import InputDateRange from '@atom/components/common/inputDateRange/InputDateRange';
import UsersFilter from '@atom/components/common/usersFilter/UsersFilter';
import { Select, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetRequestsFilters,
  DescriptionOption,
} from '@atom/types/assetRequest';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  DateRange,
  DateRangeTerminator,
} from '@atom/utilities/workOrdersDateFilterUtilities';

import {
  DESCRIPTION_LABELS,
  DESCRIPTION_OPTIONS,
  STATUS_LABELS,
} from '../assetRequestConstants';
import { getSDDOTStatusOptions } from '../customTenantUtilities';

import './sddotFilters.css';

const { MenuItem } = Select;

const styles = {
  input: {
    marginTop: '1rem',
  },
  label: {
    color: colors.neutral.dim,
  },
};

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
});

interface Props {
  filtersCart: AssetRequestsFilters;
  setFiltersCart: Dispatch<SetStateAction<AssetRequestsFilters>>;
  asset?: InventoryAssetDetailType;
  createdPresetSelected?: DateRange;
  setCreatedPresetSelect?: Dispatch<SetStateAction<DateRange>>;
  updatedPresetSelected?: DateRange;
  setUpdatedPresetSelect?: Dispatch<SetStateAction<DateRange>>;
}

const SDDOTInventoryFilters = ({
  filtersCart,
  setFiltersCart,
  asset,
  createdPresetSelected,
  setCreatedPresetSelect,
  updatedPresetSelected,
  setUpdatedPresetSelect,
}: Props) => {
  const classes = useClasses();

  const updateFilter = (value: any, property: keyof AssetRequestsFilters) => {
    setFiltersCart(prev => ({ ...prev, [property]: value }));
  };

  const getDateValue = (property: keyof AssetRequestsFilters) => {
    if (!isNilOrEmpty(filtersCart[property])) {
      return new Date(Number(filtersCart[property]));
    }

    return null;
  };

  const handleDateChange = (
    property: keyof AssetRequestsFilters,
    terminator: DateRangeTerminator,
  ) => (val: Date) => {
    const dateMillis = val
      ? terminator === DateRangeTerminator.START
        ? convertDateToLocalDayStart(val)
        : convertDateToLocalDayEnd(val)
      : null;
    updateFilter(dateMillis, property);
  };

  const handleDescriptionUpdate = event => {
    const option = event.target.value;

    const updatedFilters = {
      [DescriptionOption.ALL]: {
        assetId: asset?.id,
        fromAssetId: asset?.id,
      },
      [DescriptionOption.OUTGOING]: {
        assetId: asset?.id,
        fromAssetId: null,
      },
      [DescriptionOption.INCOMING]: {
        assetId: null,
        fromAssetId: asset?.id,
      },
    };

    setFiltersCart(prev => ({ ...prev, ...updatedFilters[option] }));
  };

  const descriptionValue = useMemo(() => {
    switch (true) {
      case !isNilOrEmpty(filtersCart.assetId) &&
        !isNilOrEmpty(filtersCart.fromAssetId): {
        return DescriptionOption.ALL;
      }
      case !isNilOrEmpty(filtersCart.assetId) &&
        isNilOrEmpty(filtersCart.fromAssetId): {
        return DescriptionOption.OUTGOING;
      }
      case isNilOrEmpty(filtersCart.assetId) &&
        !isNilOrEmpty(filtersCart.fromAssetId): {
        return DescriptionOption.INCOMING;
      }
      default:
        return '';
    }
  }, [filtersCart.assetId, filtersCart.fromAssetId]);

  const statusOptions = getSDDOTStatusOptions(filtersCart.type);
  const showDescriptionFilter = !isNilOrEmpty(asset);

  return (
    <>
      <div styleName="input-container">
        <TextField
          style={styles.input}
          InputLabelProps={{ style: styles.label }}
          type="number"
          value={filtersCart.name}
          placeholder="Enter number only"
          label="Request ID"
          onChange={event => updateFilter(String(event.target.value), 'name')}
        />
      </div>
      <div styleName="input-container">
        <Select
          key="status"
          multiple
          displayEmpty
          label="Status"
          value={filtersCart?.statuses || []}
          onChange={event => updateFilter(event.target.value, 'statuses')}
          renderValue={R.length(filtersCart?.statuses) > 0 ? null : () => 'All'}
          InputLabelProps={{ classes: { root: classes.label } }}
        >
          {statusOptions.map(status => (
            <MenuItem key={status} value={status}>
              {STATUS_LABELS[status]}
            </MenuItem>
          ))}
        </Select>
      </div>
      {showDescriptionFilter && (
        <div styleName="input-container">
          <Select
            key="description"
            label="Description"
            InputLabelProps={{ style: styles.label }}
            fullWidth
            onChange={event => handleDescriptionUpdate(event)}
            value={descriptionValue}
          >
            {DESCRIPTION_OPTIONS.map((option: string) => (
              <MenuItem key={option} value={option}>
                {DESCRIPTION_LABELS[option]}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div styleName="input-container">
        <UsersFilter
          value={filtersCart?.assignedTo}
          updateValue={newValue => updateFilter(newValue, 'assignedTo')}
          placeholder="Search users"
          label="Assigned To"
          showTitle
        />
      </div>
      <div styleName="input-container">
        <UsersFilter
          value={filtersCart?.createdBy}
          updateValue={newValue => updateFilter(newValue, 'createdBy')}
          placeholder="Search users"
          label="Created By"
          showTitle
        />
      </div>
      <div styleName="input-container">
        <InputDateRange
          label="Created Date"
          presetSelected={createdPresetSelected}
          startDateValue={getDateValue('createdDateStart')}
          handleStartChange={handleDateChange(
            'createdDateStart',
            DateRangeTerminator.START,
          )}
          endDateValue={getDateValue('createdDateEnd')}
          handleEndChange={handleDateChange(
            'createdDateEnd',
            DateRangeTerminator.END,
          )}
          handleSelectDatePreset={selected =>
            setCreatedPresetSelect(selected?.value)
          }
        />
      </div>
      <div styleName="input-container">
        <InputDateRange
          label="Resolution Date"
          presetSelected={updatedPresetSelected}
          startDateValue={getDateValue('updatedDateStart')}
          handleStartChange={handleDateChange(
            'updatedDateStart',
            DateRangeTerminator.START,
          )}
          endDateValue={getDateValue('updatedDateEnd')}
          handleEndChange={handleDateChange(
            'updatedDateEnd',
            DateRangeTerminator.END,
          )}
          handleSelectDatePreset={selected =>
            setUpdatedPresetSelect(selected?.value)
          }
        />
      </div>
    </>
  );
};

export default SDDOTInventoryFilters;
