import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import ListViewTable from '@atom/components/common/listViewTable/ListViewTable';
import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import TeamContext, { View } from '@atom/components/teamPortal/TeamContext';
import UserOptions from '@atom/components/teamPortal/teamControls/UserOptions';
import { GET_USERS, USER_DELETE, USER_UPDATE } from '@atom/graph/user';
import { Button, Progress, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  UserDetail,
  UserGroupListItem,
  UsersConnection,
  UsersConnectionInput,
  UserUpdateInput,
} from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import userUtilities from '@atom/utilities/userUtilities';

import './usersTable.css';

const styles = {
  tableStyle: {
    width: '100%',
    marginTop: 0,
    marginBottom: '3.125rem',
    height: 'calc(100vh - 215px)',
  },
  columns: {
    large: {
      width: '13rem',
    },
    medium: {
      width: '11.25rem',
    },
    mediumMax: {
      maxWidth: '12rem',
    },
    small: {
      minWidth: '3.125rem',
    },
    extraSmall: {
      width: '2.75rem',
    },
  },
  overcell: {
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    tooltip: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
};

const userFilters = {
  [View.DIRECTORY]: {},
  [View.ACTIVE]: {},
  [View.PENDING]: { isActive: false, isVerified: false },
  [View.DEACTIVATED]: { isActive: false, isVerified: true },
};

const sortableFields = ['name', 'role', 'title', 'email'];
const getDisplayHeaders = (view: View) => {
  const displayHeaders = {
    [View.DIRECTORY]: [],
    [View.ACTIVE]: [
      '',
      'Name',
      'Email',
      'Groups',
      'Roles',
      'Permission',
      'Job Title',
      '',
    ],
    [View.PENDING]: ['', 'Name', 'Email', 'Groups', 'Roles', ''],
    [View.DEACTIVATED]: [
      '',
      'Name',
      'Email',
      'Groups',
      'Roles',
      'Permission',
      'Job Title',
      '',
    ],
  };

  return displayHeaders[view];
};

const getFields = (view: View) => {
  const fields = {
    [View.DIRECTORY]: [],
    [View.ACTIVE]: [
      'photoUrl',
      'name',
      'email',
      'group',
      'roles',
      'role',
      'title',
      'additionalOptions',
    ],
    [View.PENDING]: [
      'photoUrl',
      'name',
      'email',
      'group',
      'roles',
      'verificationControls',
    ],
    [View.DEACTIVATED]: [
      'photoUrl',
      'name',
      'email',
      'group',
      'roles',
      'role',
      'title',
      'deactivationControls',
    ],
  };

  return fields[view];
};

const TOOLTIP_LIMIT = 6;

const UsersTable = () => {
  const { activeView, setFilters, page, limit, setPage, setLimit } = useContext(
    TeamContext,
  );

  const [sortBy, setSortBy] = useState<string>('name');
  const [isAscending, setIsAscending] = useState<boolean>(true);

  const { loading, error, refetch, data } = useQuery<
    { users: UsersConnection },
    { input: UsersConnectionInput }
  >(GET_USERS, {
    variables: {
      input: {
        showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
        page,
        limit,
        sortBy,
        isAscending,
        ...userFilters[activeView],
      },
    },
    fetchPolicy: 'network-only',
  });

  const [updateUser, { loading: updateLoading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteUser, { loading: deleteLoading }] = useMutation(USER_DELETE, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    setPage(1);
    setLimit(25);
    setSortBy('name');
    setIsAscending(true);
    setFilters({
      ...(sortBy && { sortBy }),
      ...(!R.isNil(isAscending) && { isAscending }),
      ...userFilters[activeView],
    });
    // eslint-disable-next-line
  }, [activeView]);

  const updateSort = (sortByField: string) => {
    const isNowAscending = sortByField === sortBy && !isAscending;

    setSortBy(sortByField);
    setIsAscending(isNowAscending);

    setFilters({
      ...(sortByField && { sortBy: sortByField }),
      ...(!R.isNil(isNowAscending) && { isAscending: isNowAscending }),
    });
  };

  const declineUser = (id: string) => {
    deleteUser({
      variables: {
        id,
      },
    });
  };

  const verifyUser = (id: string) => {
    updateUser({
      variables: {
        input: {
          id,
          isVerified: true,
        },
      },
    });
  };

  const activateUser = (id: string) => {
    updateUser({
      variables: {
        input: {
          id,
          isActive: true,
        },
      },
    });
  };

  const buildUserGroupTooltip = (userGroups: UserGroupListItem[]) => {
    const isOverFive = userGroups.length > TOOLTIP_LIMIT;
    const filterUserGroups = isOverFive
      ? userGroups.slice(1, TOOLTIP_LIMIT)
      : userGroups.slice(1, userGroups.length);
    return (
      <div>
        {filterUserGroups.map((userGroup: UserGroupListItem, num: number) => (
          <div key={num}>
            {!R.isEmpty(userGroup) && (
              <div style={styles.overcell.tooltip}>
                {R.pathOr([], ['groupPath'], userGroup).join('/')}
              </div>
            )}
          </div>
        ))}
        {isOverFive && (
          <div style={{ color: colors.neutral.gray, fontStyle: 'italic' }}>
            See user details for complete list
          </div>
        )}
      </div>
    );
  };

  const buildUserGroupCell = (userGroups: UserGroupListItem[]) => {
    const tooltipText = R.pathOr([], ['groupPath'], userGroups[0]).join('/');
    return (
      <div>
        {userGroups.length === 1 ? (
          <TextOverflowTooltip
            text={tooltipText}
            width="12.5rem"
            lightTooltip
          />
        ) : (
          <div style={styles.overcell.row}>
            <TextOverflowTooltip
              text={tooltipText}
              width="10.5rem"
              lightTooltip
            />
            <Tooltip
              lightTooltip
              title={buildUserGroupTooltip(userGroups)}
              style={{ color: colors.neutral.gray }}
              placement="right"
            >
              <div>{`+${userGroups.length - 1}`}</div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const buildUserRolesTooltip = (roles: string[]) => {
    const isOverFive = roles.length > TOOLTIP_LIMIT;
    const filterUserRoles = isOverFive
      ? roles.slice(1, TOOLTIP_LIMIT)
      : roles.slice(1, roles.length);
    return (
      <div>
        {filterUserRoles.map((role: string, num: number) => (
          <div key={num} style={styles.overcell.tooltip}>
            {role}
          </div>
        ))}
        {isOverFive && (
          <div style={{ color: colors.neutral.gray, fontStyle: 'italic' }}>
            See roles in user details for complete list
          </div>
        )}
      </div>
    );
  };

  const buildUserRolesCell = (roles: string[]) => {
    const tooltipText = roles[0];
    return (
      <div>
        {roles.length === 1 ? (
          <TextOverflowTooltip text={tooltipText} width="14rem" lightTooltip />
        ) : (
          <div style={styles.overcell.row}>
            <TextOverflowTooltip
              text={tooltipText}
              width="11rem"
              lightTooltip
            />
            <Tooltip
              lightTooltip
              title={buildUserRolesTooltip(roles)}
              style={{ color: colors.neutral.gray }}
              placement="right"
            >
              <div>{`+${roles.length - 1}`}</div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const buildUserCell = (row: UserDetail, field: string, index: number) => {
    const roles = [
      ...new Set([...row.roles, ...row.inheritedRoles].map(role => role.name)),
    ];

    const fieldTemplates = {
      photoUrl: (
        <td style={styles.columns.small} key={index}>
          <UserThumbnail
            image={row.photoUrl}
            alt={`${row.firstName} ${row.lastName}`}
            pending={activeView === View.PENDING}
          />
        </td>
      ),
      name: (
        <td style={styles.columns.large} key={index} styleName="clickable">
          <Link to={`/team/${row.id}`}>
            <div styleName="title">
              <TextOverflowTooltip
                text={`${row.firstName} ${row.lastName}`}
                width="12.5rem"
                lightTooltip
              />
            </div>
          </Link>
        </td>
      ),
      email: (
        <td style={styles.columns.large} key={index}>
          <TextOverflowTooltip text={row.email} width="12.5rem" lightTooltip />
        </td>
      ),
      group: (
        <td style={styles.columns.large} key={index}>
          {!R.isEmpty(row.userGroups) && buildUserGroupCell(row.userGroups)}
        </td>
      ),
      roles: (
        <td key={index} style={styles.columns.large}>
          {!R.isEmpty(roles) && buildUserRolesCell(roles)}
        </td>
      ),
      role: (
        <td key={index} style={styles.columns.mediumMax}>
          {userUtilities.getUserRole(row[field])}
        </td>
      ),
      title: (
        <td key={index} style={styles.columns.mediumMax}>
          {row.title}
        </td>
      ),
      additionalOptions: (
        <td key={index} style={styles.columns.extraSmall}>
          <UserOptions user={row} refetch={refetch} />
        </td>
      ),
      verificationControls: (
        <td key={index} styleName="button-controls">
          {hasRolePermissions(ROLE_SETS.MANAGER) && (
            <div styleName="verification-controls-container">
              <Button onClick={() => declineUser(row.id)}>Decline</Button>
              <Button onClick={() => verifyUser(row.id)} color="primary">
                Approve
              </Button>
            </div>
          )}
        </td>
      ),
      deactivationControls: (
        <td key={index} styleName="button-controls">
          {hasRolePermissions(ROLE_SETS.MANAGER) && (
            <div styleName="activation-controls-container">
              <Button onClick={() => activateUser(row.id)} color="primary">
                Activate
              </Button>
            </div>
          )}
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  const getTitleText = () => {
    const title = {
      [View.ACTIVE]: 'active users',
      [View.PENDING]: 'pending requests',
      [View.DEACTIVATED]: 'deactivated users',
    };

    return title[activeView];
  };

  const userLoading = loading || updateLoading || deleteLoading || error;
  const totalCount = R.pathOr(0, ['users', 'totalCount'], data);
  const users = R.pathOr([], ['users', 'users'], data);
  const displayHeaders = getDisplayHeaders(activeView);
  const fields = getFields(activeView);

  return (
    <>
      <div styleName="table-title">{`${totalCount} ${getTitleText()}`}</div>
      {userLoading ? (
        <Progress />
      ) : (
        <ListViewTable
          style={styles.tableStyle}
          displayHeaders={displayHeaders}
          dataRows={users}
          fields={fields}
          // @ts-ignore
          buildCell={buildUserCell}
          totalRows={totalCount}
          // @ts-ignore
          incrementPage={(increment: number) => setPage(page + increment)}
          // @ts-ignore
          updateLimit={(newLimit: number) => {
            setPage(1);
            setLimit(newLimit);
          }}
          page={page}
          limit={limit}
          sortableFields={sortableFields}
          sortBy={sortBy}
          isAscending={isAscending}
          // @ts-ignore
          sortRows={updateSort}
        />
      )}
    </>
  );
};

export default UsersTable;
