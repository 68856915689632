import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import { getApprovalKeyword } from '@atom/components/common/requests/customTenantUtilities';
import { GET_ASSET } from '@atom/graph/asset';
import {
  GET_ASSET_REQUEST,
  UPDATE_ASSET_REQUEST,
} from '@atom/graph/assetRequest';
import client from '@atom/graph/client';
import { Modal, Progress } from '@atom/mui';
import {
  AssetRequest,
  AssetRequestStatus,
  AssetRequestType,
  AssetRequestUpdateInput,
} from '@atom/types/assetRequest';
import { AssetDetail } from '@atom/types/inventory';

import SDDOTAdjustmentView from './sddotView/SDDOTAdjustmentView';
import SDDOTInventoryView from './sddotView/SDDOTInventoryView';
import SDDOTReplenishmentView from './sddotView/SDDOTReplenishmentView';
import SDDOTStockpileView from './sddotView/SDDOTStockplieView';
import ViewRequestContext from './ViewRequestContext';
import ViewRequestHeader from './ViewRequestHeader';

import './viewRequest.css';

const styles = {
  modal: {
    padding: '1.5rem',
  },
};

interface Props {
  activeRequestId: string;
  setActiveRequestId: (id: string) => void;
  changeType?: (type: AssetRequestType) => void;
}

const ViewRequestModal = ({
  activeRequestId,
  setActiveRequestId,
  changeType = () => {},
}: Props) => {
  const [activeRequest, setActiveRequest] = useState<AssetRequest>();
  const [fromAsset, setFromAsset] = useState<AssetDetail>();
  const [assetLoading, setAssetLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<AssetRequestStatus>();
  const [quantityApproved, setQuantityApproved] = useState<number>();
  const [comments, setComments] = useState<string>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const handleGetFromAsset = async (id: string) => {
    if (!id) {
      return;
    }

    setAssetLoading(true);
    const { data } = await client.query<{ asset: AssetDetail }, { id: string }>(
      {
        query: GET_ASSET,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      },
    );

    setAssetLoading(false);
    setFromAsset(data?.asset);
  };

  const [getActiveRequest, { loading: activeRequestLoading }] = useLazyQuery<
    { assetRequest: AssetRequest },
    { id: string }
  >(GET_ASSET_REQUEST, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setActiveRequest(res.assetRequest);
      changeType(res?.assetRequest?.type);

      if (
        res?.assetRequest?.type === AssetRequestType.SDDOT_INVENTORY_TRANSFER
      ) {
        handleGetFromAsset(res?.assetRequest?.fromAssetId);
      }
    },
  });

  const handleGetActiveRequest = () => {
    getActiveRequest({
      variables: {
        id: activeRequestId,
      },
    });
  };

  const [updateAssetRequest] = useMutation<
    { assetRequestUpdate: AssetRequest },
    { input: AssetRequestUpdateInput }
  >(UPDATE_ASSET_REQUEST);

  useEffect(() => {
    if (!activeRequestId) {
      setStatus(null);
      setQuantityApproved(null);
      setComments(null);
      setActiveRequest(null);
      setFromAsset(null);
      setAssetLoading(false);
    }
  }, [activeRequestId]);

  useEffect(() => {
    if (!!activeRequestId) {
      handleGetActiveRequest();
    } else {
      setActiveRequest(null);
      setFromAsset(null);
    }
  }, [activeRequestId]);

  const updateRequest = async () => {
    setConfirmOpen(false);

    const requestId = activeRequestId;
    setActiveRequestId(null);

    await updateAssetRequest({
      variables: {
        input: {
          id: requestId,
          status,
          ...R.reject(R.isNil, {
            comments,
            quantityApproved,
          }),
        },
      },
    });

    setActiveRequestId(requestId);
  };

  const open = !!activeRequestId && !confirmOpen;

  const modalContent = {
    [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: <SDDOTInventoryView />,
    [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]: <SDDOTStockpileView />,
    [AssetRequestType.SDDOT_REPLENISHMENT]: <SDDOTReplenishmentView />,
    [AssetRequestType.SDDOT_ADJUSTMENT]: <SDDOTAdjustmentView />,
  };

  const isApproval =
    status === AssetRequestStatus.FULL_APPROVAL ||
    status === AssetRequestStatus.PARTIAL_APPROVAL;

  const approvalKeyword = getApprovalKeyword(status);
  const cancelText =
    status === AssetRequestStatus.CANCELED ? 'Do Not Cancel' : 'Cancel';
  const confirmText =
    status === AssetRequestStatus.CANCELED ? 'Cancel Request' : approvalKeyword;
  const buttonColor = isApproval ? 'primary' : 'error';

  const modalWidth =
    activeRequest?.type === AssetRequestType.SDDOT_REPLENISHMENT ? 'xl' : 'sm';

  const loading = assetLoading || activeRequestLoading;

  return (
    <ViewRequestContext.Provider
      value={{
        fromAsset,
        activeRequest,
        handleGetActiveRequest,
        setActiveRequestId,
        status,
        setStatus,
        quantityApproved,
        setQuantityApproved,
        comments,
        setComments,
        setConfirmOpen,
      }}
    >
      <>
        <Modal
          title={<ViewRequestHeader />}
          open={open}
          onCancel={() => setActiveRequestId(null)}
          data-cy="viewAssetRequest"
          contentStyle={styles.modal}
          width={modalWidth}
          disableFooter
        >
          {loading ? <Progress /> : modalContent[activeRequest?.type]}
        </Modal>
        <Modal
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          cancelButtonText={cancelText}
          onConfirm={updateRequest}
          confirmButtonText={confirmText}
          title={`${approvalKeyword} Request: Inventory Transfer?`}
          ConfirmButtonProps={{ color: buttonColor }}
        >
          {`Are you sure you want to ${approvalKeyword} this request?`}
        </Modal>
      </>
    </ViewRequestContext.Provider>
  );
};

export default ViewRequestModal;
